<template>
  <div ref="print" id="printMe" style="width: 398px;height: 278px;border: 1px black solid">
    <div style="padding-left: 16px">
      <div style="font-size: 20px;line-height:30px;font-weight: bold;margin-top: 20px">设备标签</div>
      <div style="font-size: 16px;line-height:40px;">设备名称:HD-2000 GPS γ辐射仪</div>
    </div>
     <div style="overflow: hidden;display: flex;margin-top: 30px">
       <div style="flex: 1;padding-left: 16px">
         <div style="line-height: 30px">设备编号:{{handle}}</div>
         <div style="line-height: 30px">规格型号:HD-2000</div>
         <div style="line-height: 30px">启用日期:{{date}}</div>
         <div style="line-height: 30px">存放地点:库房</div>
       </div>
       <vue-qr
           :size="600"
           :margin="0"
           :auto-color="true"
           :dot-scale="1"
           :text="`https://fwapi.lfsnms.com/h?h=${handle}`"
           class="codeImage"
           style="width: 120px;height:120px;margin-right: 20px"
       />
     </div>
  </div>
</template>
<script>
import VueQr from 'vue-qr'
export default {
  components:{VueQr},
  props:{
    handle:{
      type: String,
      default: '86.122.2/XXX'
    },
    date:{
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss'
    }
  },
  data(){
    return {
      printHandle: '86.122',
      barcodeOption: {
        displayValue: false
      }
    }
  },
  methods:{
    print(){

    }
  }
}
</script>
