<template>
  <div>
    <el-dialog
      title=""
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
        <!-- <div class="contentBox" :style="`background-image: url(${pagesData.picList? pagesData.picList[0].url : ''});background-size: 100% 100%; background-color: ${pagesData.bgColor};text-align: center; overflow: scroll; padding: ${pagesData.paddTop}px ${pagesData.paddLeft}px`">
          <div v-for="(item,index) in showList" :key="item.type+index" class="showBox">
                <div v-if="item.type==='swipe'" @click="changeIndex(index, $event)" :class="'pag_'+index">
                  <my-swipe :item="item" class="showItem" style="position: relative" :class="[index === curSelIndex?'item-sel':'']" />
                </div>
                <div
                  v-if="item.type==='mulImage'||item.type==='image'"
                  class="showItem"
                  style="overflow: hidden"
                >
                  <my-image :item="item" />
                </div>
                <div
                  v-if="item.type==='text'"
                  class="showItem"
                >
                  <my-text :item="item" />
                </div>
                <div
                  v-if="item.type==='assist'"
                  class="showItem"
                >
                  <AssistKb :item="item" />
                </div>
                <div
                  v-if="item.type==='qrcode'"
                  class="showItem"
                >
                  <qr-code-edit :data="item" />
                </div>
                <div
                  v-if="item.type==='scanOne'"
                  class="showItem"
                >
                  <scanOne :data="item" />
                </div>
                <div
                 v-if="item.type==='scanTwo'"
                 class="showItem"
                >
                  <ScanTwo :data="item"></ScanTwo>
                </div>
                <div
                  v-if="item.type==='scanThree'"
                  class="showItem"
                >
                  <ScanThree :data="item"></ScanThree>
                </div>
                <div
                  v-if="item.type==='scanFour'"
                  class="showItem"
                >
                  <ScanFour :data="item"></ScanFour>
                </div>
                <div
                  v-if="item.type==='scanFive'"
                  class="showItem"
                >
                  <ScanFive :data="item"></ScanFive>
                </div>
                <div
                  v-if="item.type==='bottonmMenu1'"
                  class="showItem"
                >
                  <bottonmMenu1 :item="item"></bottonmMenu1>
                </div>
                <div
                  v-if="item.type=== 'searchInput'"
                  class="showItem"
                >
                  <my-search-input :item="item" />
                </div>
                <div
                  v-if="item.type=== 'imgTextList'"
                  class="showItem"
                >
                  <my-imgText-list :item="item" />
                </div>
                <div
                  v-if="item.type=== 'imgText2'"
                  class="showItem"
                >
                  <MyImgText2 :item="item"></MyImgText2>
                </div>
                <div
                  v-if="item.type=== 'detailBlock1'"
                  class="showItem"
                >
                  <DetailBlock1 :item="item"></DetailBlock1>
                </div>
                <div
                  v-if="item.type=== 'tit1'"
                  class="showItem"
                >
                  <Title1 :item="item"></Title1>
                </div>
                <div
                  v-if="item.type=== 'con1'"
                  class="showItem"
                >
                  <ConStyle1 :item="item"></ConStyle1>
                </div>
                <div
                  v-if="item.type === 'productGroup'"
                  class="showItem"
                >
                  <productGroup :item="item" />
                </div>
                <div
                  v-if="item.type === 'oneVideo'"
                  class="showItem"
                >
                  <OneVideo :item="item"></OneVideo>
                </div>
                <div
                  v-if="item.type === 'expertTalk'"
                  class="showItem"
                >
                  <ExpertTalk :item="item"></ExpertTalk>
                </div>
                <div
                  v-if="item.type ==='chatFix'"
                  class="showItem"
                >
                  <ChatFix :item="item"></ChatFix>
                </div>
                <div
                  v-if="item.type ==='mulVideo'"
                  class="showItem"
                >
                  <MulVideo :item="item"></MulVideo>
                </div>
                <div
                  v-if="item.type==='pages'"
                  class="showItem"
                >
                  <PagesSet :data="pagesData" />
                </div>
          </div>

        </div> -->
        <div class="container">
          <customShowPage v-if="pageId" :curPageId="pageId"></customShowPage>
        </div>
        
    </el-dialog>
  </div>
</template>
<script>
import { fetchPageData } from '@/api/customPage.js'
// 页面展示
// import ScanOne from '@/pages/customPage/module/ScanOne'
// import ScanTwo from '@/pages/customPage/module/ScanTwo'
// import ScanThree from '@/pages/customPage/module/ScanThree'
// import ScanFour from '@/pages/customPage/module/ScanFour'
// import ScanFive from '@/pages/customPage/module/ScanFive'
// import PagesSet from '@/pages/customPage/module/PageSet'
// import MySwipe from '@/pages/customPage/module/MySwipe'
// import MyImage from '@/pages/customPage/module/MyImage'
// import MyText from '@/pages/customPage/module/MyText'
// import AssistKb from '@/pages/customPage/module/AssistKb'
// import MySearchInput from '@/pages/customPage/module/MySearchInput'
// import MyImgTextList from '@/pages/customPage/module/MyImgTextList'
// import MyImgText2 from '@/pages/customPage/module/MyImgText2'
// import DetailBlock1 from '@/pages/customPage/module/DetailBlock1'
// import Title1 from '@/pages/customPage/module/Title1'
// import ConStyle1 from '@/pages/customPage/module/ConStyle1'
// import productGroup from '@/pages/customPage/module/ProductGroup'
// import BottonmMenu1 from '@/pages/customPage/module/BottonmMenu1'
// import OneVideo from '@/pages/customPage/module/OneVideo'
// import ExpertTalk from '@/pages/customPage/module/ExpertTalk'
// import ChatFix from '@/pages/customPage/module/ChatFix'
// import MulVideo from '@/pages/customPage/module/MulVideo'
import customShowPage from '@/pages/customPageList/customPageShow'
export default {
  components:{
    // ScanOne,
    // ScanTwo,
    // ScanThree,
    // ScanFour,
    // ScanFive,
    // PagesSet,
    // MySwipe,
    // MyImage,
    // MyText,
    // AssistKb,
    // MySearchInput,
    // MyImgTextList,
    // MyImgText2,
    // DetailBlock1,
    // Title1,
    // ConStyle1,
    // productGroup,
    // BottonmMenu1,
    // OneVideo,
    // ExpertTalk,
    // ChatFix,
    // MulVideo,
    customShowPage,
  },
  data(){
    return {
      visible: false,
      showList: [], // 所有组件模块
      pagesData: {}, // 整个页面设置
      handle: '',
      pageId: '',
      curSelIndex: 0,
    }
  },
  computed: {
    handleLink(){
      // return `https://fwapi.lfsnms.com/h/h?h=86.122.2/n09k2ufx`
      return `https://fwapi.lfsnms.com/h?h=${this.handle}`
    }
  },
  created(){
    // if(this.$route.query.data) {
    //   let data = JSON.parse(this.$route.query.data);
    //   let content = JSON.parse(data.content)
    //   this.showList = content.datas
    //   this.pagesData = content.pageData
    // }
    // if(this.$route.query.customPageId) {
    //   this.pageId = this.$route.query.customPageId
    //   if(this.$route.query.handle) {
    //     this.handle = this.$route.query.handle
    //   }
    //   this.getPageData()
    // }
  },
  methods:{
    init(customPageId) {
      this.pageId = customPageId
    },
    handleClose(done) {
      this.pageId = ''
      done()
    },
    async getPageData() {
      const result = await fetchPageData(this.pageId)
      let content = JSON.parse(result.data.content)
      this.showList = content.datas
      this.pagesData = content.pageData
      console.log(result)
    }
  }
  
}
</script>
<style scoped>
.container .mobileContainer {
  height: 550px!important;
}
</style>