<template>
  <div class="mobileContainer">
    <div class="contentBox" :style="`background-image: url(${pagesData.picList? pagesData.picList[0].url : ''});background-size: 100% 100%; background-color: ${pagesData.bgColor};text-align: center; padding: ${pagesData.paddTop}px ${pagesData.paddLeft}px`">
      <div v-for="(item,index) in showList" :key="item.type+index" class="showBox">
        <div v-if="item.type==='swipe'"  :class="'pag_'+index">
                  <my-swipe :item="item" class="showItem" style="position: relative" :class="[index === curSelIndex?'item-sel':'']" />
                </div>
                <div
                  v-if="item.type==='mulImage'||item.type==='image'"
                  class="showItem"
                  style="overflow: hidden"
                >
                  <my-image :item="item" />
                </div>
                <div
                  v-if="item.type==='text'"
                  class="showItem"
                >
                  <my-text :item="item" />
                </div>
                <div
                  v-if="item.type==='assist'"
                  class="showItem"
                >
                  <AssistKb :item="item" />
                </div>
                <div
                  v-if="item.type==='qrcode'"
                  class="showItem"
                >
                  <qr-code-edit :data="item" />
                </div>
                <div
                  v-if="item.type==='scanOne'"
                  class="showItem"
                >
                  <scanOne :data="item" />
                </div>
                <div
                 v-if="item.type==='scanTwo'"
                 class="showItem"
                >
                  <ScanTwo :data="item"></ScanTwo>
                </div>
                <div
                  v-if="item.type==='scanThree'"
                  class="showItem"
                >
                  <ScanThree :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanThree>
                </div>
                <div
                  v-if="item.type==='scanFour'"
                  class="showItem"
                >
                  <ScanFour :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanFour>
                </div>
                <div
                  v-if="item.type==='scanFive'"
                  class="showItem"
                >
                  <ScanFive :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanFive>
                </div>
                 <!--边缘计算网关--->
                 <div v-if="item.type === 'edgeGateway'" class="showItem" :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" >
                   <EdgeGateway :item="item"></EdgeGateway>
                 </div>
                  <!--核辐射设备-->
                  <div v-if="item.type === 'equipment'" class="showItem" :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" >
                    <Equipment :item="item"></Equipment>
                  </div>
                  
                  <div v-if="item.type === 'deviceNameplate'" class="showItem"
                    :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]"
                   >
                    <DeviceNameplate :item="item"></DeviceNameplate>
                  </div>
                <div
                  v-if="item.type==='scanSix'"
                  class="showItem"
                >
                  <ScanSix :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanSix>
                </div>
                <div
                  v-if="item.type==='scanSeven'"
                  class="showItem"
                >
                  <ScanSeven :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanSeven>
                </div>

                <div
                  v-if="item.type==='scanNine'"
                  class="showItem"
                >
                  <ScanNine :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanNine>
                </div>
                <div
                  v-if="item.type==='scanTen'"
                  class="showItem"
                >
                  <ScanTen :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanTen>
                </div>

                <div
                        v-if="item.type==='myQrCode'"
                        class="showItem"
                >
                    <MyQrCode :data="item" @veridicalCode="veridicalCode"
                              :verState="verState"></MyQrCode>
                </div>
                <div
                  v-if="item.type==='bottonmMenu1'"
                  class="showItem"
                >
                  <bottonmMenu1 :item="item"></bottonmMenu1>
                </div>
                <div
                  v-if="item.type==='bottonmMenu2'"
                  class="showItem"
                >
                  <bottonmMenu2 :item="item"></bottonmMenu2>
                </div>
								
								<div
								  v-if="item.type==='shoppingBtn'"
								  class="showItem"
								>
								  <shoppingBtn :item="item"></shoppingBtn>
								</div>
								
                <div
                  v-if="item.type=== 'searchInput'"
                  class="showItem"
                >
                  <my-search-input :item="item" />
                </div>
                <div
                  v-if="item.type=== 'imgTextList'"
                  class="showItem"
                >
                  <my-imgText-list :item="item" />
                </div>
                <div
                  v-if="item.type=== 'imgText3'"
                  class="showItem"
                >
                  <MyImgText3 :item="item" />
                </div>
                <div
                  v-if="item.type=== 'imgText2'"
                  class="showItem"
                >
                  <MyImgText2 :item="item"></MyImgText2>
                </div>
                <div
                  v-if="item.type=== 'titleImg'"
                  class="showItem"
                >
                  <TitleImg :item="item"></TitleImg>
                </div>
								<div
								  v-if="item.type=== 'qkPage'"
								  class="showItem"
								>
								  <QKPage :item="item"></QKPage>
								</div>
								
								<div
								  v-if="item.type=== 'userInfo'"
								  class="showItem"
								>
								  <SetUserInfo :item="item"></SetUserInfo>
								</div>
								
								<div
								  v-if="item.type=== 'setHandle'"
								  class="showItem"
								>
								  <SetHandle :item="item"></SetHandle>
								</div>
								
								<div
								  v-if="item.type=== 'setParameter'"
								  class="showItem"
								>
								  <SetParameter :item="item"></SetParameter>
								</div>
					
                <div
                  v-if="item.type=== 'detailBlock1'"
                  class="showItem"
                >
                  <DetailBlock1 :item="item"></DetailBlock1>
                </div>
                <div
                  v-if="item.type=== 'tit1'"
                  class="showItem"
                >
                  <Title1 :item="item"></Title1>
                </div>
                <div
                  v-if="item.type ==='moreStyleTitle'"
                  class="showItem"
                >
                  <MoreStyleTitle :item="item"></MoreStyleTitle>
                </div>
                <div
                  v-if="item.type=== 'con1'"
                  class="showItem"
                >
                  <ConStyle1 :item="item"></ConStyle1>
                </div>
                <div
                  v-if="item.type === 'productGroup'"
                  class="showItem"
                >
                  <productGroup :item="item" />
                </div>
                <div
                  v-if="item.type === 'oneVideo'"
                  class="showItem"
                >
                  <OneVideo :item="item"></OneVideo>
                </div>
                <div
                  v-if="item.type === 'expertTalk'"
                  class="showItem"
                >
                  <ExpertTalk :item="item"></ExpertTalk>
                </div>
                <div
                  v-if="item.type === 'aloneTalk'"
                  class="showItem"
                >
                  <AloneTalk :item="item"></AloneTalk>
                </div>
                <div
                  v-if="item.type ==='chatFix'"
                  class="showItem"
                >
                  <ChatFix :item="item"></ChatFix>
                </div>
                <div
                  v-if="item.type ==='mulVideo'"
                  class="showItem"
                >
                  <MulVideo :item="item"></MulVideo>
                </div>
                <div
                    v-if="item.type ==='pdfShow'"
                    class="showItem"
                >
                  <pdfShow :item="item"></pdfShow>
                </div>
                <div
                  v-if="item.type ==='report'"
                  class="showItem"
                >
                  <MyReport :item="item"></MyReport>
                </div>
                <div
                  v-if="item.type==='proInfo1'"
                  class="showItem"
                >
                  <ProInfo1Show :item="item"></ProInfo1Show>
                </div>
                <div
                  v-if="item.type==='proInfo1'"
                  class="showItem"
                >
                  <ProInfo1Show :item="item"></ProInfo1Show>
                </div>
                <div
                  v-if="item.type==='productCode'"
                  class="showItem"
                >
                  <ProductCode :data="item"></ProductCode>
                </div>
                <div
                  v-if="item.type==='packingCode'"
                  class="showItem"
                >
                  <PackingCode :data="item"></PackingCode>
                </div>

                <div
                  v-if="item.type==='boxCode'"
                  class="showItem"
                >
                  <BoxCode :data="item"></BoxCode>
                </div>

                <div
                  v-if="item.type==='boxCode2'"
                  class="showItem"
                >
                  <BoxCode2 :data="item"></BoxCode2>
                </div>

                <div
                  v-if="item.type ==='xhCertificate'"
                  class="showItem"
                >
                  <XhCertificate :item="item"></XhCertificate>
                </div>

                <div
                  v-if="item.type ==='xhCertificate2'"
                  class="showItem"
                >
                  <XhCertificate2 :item="item"></XhCertificate2>
                </div>

                <!--udi码展示-->
                <div v-if="item.type === 'datamatrix'" 
                  class="showItem"
                  :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" 
                  >
                  <MyDatamatrix :item="item" @veridicalCode="veridicalCode" :verState="verState"></MyDatamatrix>
                </div>

                <!--酒瓶NFC-->
                <div v-if="item.type === 'bottleState'" class="showItem"
								  :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" >
								  <BottleState :item="item"></BottleState>
								</div>

                <div v-if="item.type === 'scanBJWL'" class="showItem"
                  :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" >
                  <ScanBJWL :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanBJWL>
                </div>

                <div v-if="item.type === 'bottonmMenu3'" class="showItem"
                  :class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" >
                  <bottonmMenu3 :item="item"></bottonmMenu3>
                </div>

                 <!--张裕白兰地-->
									<div v-if="item.type === 'BLDWine'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]"
									@click="changeIndex(index, $event)"
									>
										<BLDWine :item="item"></BLDWine>
									</div>

                <div
                  v-if="item.type==='pages'"
                  class="showItem"
                >
                  <PagesSet :data="pagesData" />
                </div>

      </div>
    </div>
  </div>
</template>

<script>
import { fetchPageData } from '@/api/customPage.js'
// 页面展示
import ScanOne from '@/pages/customPage/module/ScanOne'
import ScanTwo from '@/pages/customPage/module/ScanTwo'
import ScanThree from '@/pages/customPage/module/ScanThree'
import ScanFour from '@/pages/customPage/module/ScanFour'
import ScanFive from '@/pages/customPage/module/ScanFive'
import ScanSix from '@/pages/customPage/module/ScanSix'
import ScanSeven from '@/pages/customPage/module/ScanSeven'
import ScanNine from '@/pages/customPage/module/ScanNine'
import ScanTen from '@/pages/customPage/module/ScanTen'
import PagesSet from '@/pages/customPage/module/PageSet'
import MySwipe from '@/pages/customPage/module/MySwipe'
import MyImage from '@/pages/customPage/module/MyImage'
import MyText from '@/pages/customPage/module/MyText'
import AssistKb from '@/pages/customPage/module/AssistKb'
import MySearchInput from '@/pages/customPage/module/MySearchInput'
import MyImgTextList from '@/pages/customPage/module/MyImgTextList'
import MyImgText2 from '@/pages/customPage/module/MyImgText2'
import MyImgText3 from '@/pages/customPage/module/MyImgText3'
import TitleImg from '@/pages/customPage/module/TitleImg'
import DetailBlock1 from '@/pages/customPage/module/DetailBlock1'
import Title1 from '@/pages/customPage/module/Title1'
import ConStyle1 from '@/pages/customPage/module/ConStyle1'
import productGroup from '@/pages/customPage/module/ProductGroup'
import BottonmMenu1 from '@/pages/customPage/module/BottonmMenu1'
import BottonmMenu2 from '@/pages/customPage/module/BottonmMenu2'
import shoppingBtn from '@/pages/customPage/module/ShoppingBtn'
import OneVideo from '@/pages/customPage/module/OneVideo'
import ExpertTalk from '@/pages/customPage/module/ExpertTalk'
import ChatFix from '@/pages/customPage/module/ChatFix'
import MulVideo from '@/pages/customPage/module/MulVideo'
import pdfShow from '@/pages/customPage/module/PdfShow'
import MyReport from '@/pages/customPage/module/MyReport'
import ProInfo1Show from '@/pages/customPage/module/ProInfo1Show'
import MoreStyleTitle from '@/pages/customPage/module/MoreStyleTitle'
import MyQrCode from '@/pages/customPage/module/MyQrCode'
import ProductCode from '@/pages/customPage/module/ProductCode'
import XhCertificate from '@/pages/customPage/module/XhCertificate'
import XhCertificate2 from '@/pages/customPage/module/XhCertificate2'
import QKPage from '@/pages/customPage/module/qkPage'
import PackingCode from '@/pages/customPage/module/PackingCode'
import SetHandle from '@/pages/customPage/module/SetHandle'
import SetParameter from '@/pages/customPage/module/SetParameter'
import SetUserInfo from '@/pages/customPage/module/SetUserInfo'
import MyDatamatrix from '@/pages/customPage/module/MyDatamatrix'
import ScanBJWL from '@/pages/customPage/module/ScanBJWL'
import BottonmMenu3 from '@/pages/customPage/module/BottonmMenu3'
import AloneTalk from '@/pages/customPage/module/AloneTalk'
import BoxCode from '@/pages/customPage/module/BoxCode'
import BoxCode2 from '@/pages/customPage/module/BoxCode2'
import BottleState from '@/pages/customPage/module/BottleState'
import EdgeGateway from '@/pages/customPage/module/EdgeGateway'
import Equipment from '@/pages/customPage/module/Equipment'
import DeviceNameplate from '@/pages/customPage/module/DeviceNameplate'
import BLDWine from '@/pages/customPage/module/BLDWine'

export default {
  name: "customPageShow",
  components:{
    ScanOne,
    ScanTwo,
    ScanThree,
    ScanFour,
    ScanFive,
    ScanSix,
    ScanSeven,
    ScanNine,
    ScanTen,
    PagesSet,
    MySwipe,
    MyImage,
    MyText,
    AssistKb,
    MySearchInput,
    MyImgTextList,
    MyImgText2,
    MyImgText3,
    TitleImg,
    DetailBlock1,
    Title1,
    ConStyle1,
    productGroup,
    BottonmMenu1,
    BottonmMenu2,
    OneVideo,
    ExpertTalk,
    ChatFix,
    MulVideo,
    pdfShow,
    MyReport,
    ProInfo1Show,
    MoreStyleTitle,
    MyQrCode,
    ProductCode,
    XhCertificate,
    XhCertificate2,
    QKPage,
    PackingCode,
    SetHandle,
    SetParameter,
    SetUserInfo,
    MyDatamatrix,
    ScanBJWL,
    BottonmMenu3,
    BoxCode,
    BoxCode2,
    BottleState,
    EdgeGateway,
    Equipment,
    DeviceNameplate,
    BLDWine,
		shoppingBtn
  },
  data() {
    return {
      showList: [], // 所有组件模块
      pagesData: {}, // 整个页面设置
      handle: '',
      pageId: '',
    };
  },
  props:['curPageId'],
  computed: {
  },
  watch:{

  },
  mounted() {
  },
  computed: {
    handleLink(){
      // return `https://fwapi.lfsnms.com/h?h=86.122.2/n09k2ufx`
      return `https://fwapi.lfsnms.com/h?h=${this.handle}`
    }
  },
  created(){
    if(this.$route.query.data) {
      let data = JSON.parse(this.$route.query.data);
      let content = JSON.parse(data.content)
      this.showList = content.datas
      this.pagesData = content.pageData
    }
    if(this.$route.query.customPageId) {
      this.pageId = this.$route.query.customPageId
      if(this.$route.query.handle) {
        this.handle = this.$route.query.handle
      }
      this.getPageData()
    }
    if(this.curPageId) {
      this.getPageData(this.curPageId)
    }
  },
  methods:{
    async getPageData(pid) {
      const result = await fetchPageData(this.pageId ? this.pageId : pid)
      let content = JSON.parse(result.data.content)
      this.showList = content.datas
      this.pagesData = content.pageData
      console.log(result)
    }
  }
};
</script>

<style scoped lang='scss'>
.showBox{
  font-size: 0;
}
.mobileContainer{
  max-width: 600px;
  margin: 0 auto;
  /* // // border: 1px solid #f00; */
  height: 100vh;
  overflow-y: scroll;
}
</style>